// const NG_DEMO_2_SERVER = '169.50.175.11';
// const INFINITY_SEVER = '188.214.133.61';
// const DMA_CAPITALS_SERVER = '74.208.181.195';
const OLD_ZENITH_SERVER = '188.214.132.149';
// const ZENITH_SERVER = '169.50.185.249';
// const INVISOT_SERVER = '188.214.132.143';
// const GALAXY_SERVER = '45.9.248.174';
// const FUTUREIX_SERVER = '188.214.132.176';
const NTRADER_DEV_SERVER = '188.214.132.46';
const xwwe = '188.214.133.154';
const ENVIFX = '5.79.91.202';
const EQUINOX = '5.199.172.21';
// const LOCALHOST_SERVER = "127.0.0.1";

export const QUOTES_SERVER_IP = EQUINOX;
export const QUOTES_SERVER_PORT = 9001;

export const DEFAULT_PERSISION = 5;

// INDICATORS DEFAULT PARAMS
export const MOVING_AVERAGE_DEFAULT_PERIOD = 10;
export const BOLLINGER_DEFAULT_PERIOD = 20;
export const BOLLINGER_DEFAULT_STD = 2;

export const MACD_FAST_PERIOD = 12;
export const MACD_SLOW_PERIOD = 26;
export const MACD_SIGNAL_PERIOD = 9;
export const MACD_SOURCE = 'close';
export const MACD_COLORS = {
  positive: 'rgb(87, 225, 90)',
  negative: 'rgb(242, 142, 44)',
  signalLine: '#2962FF',
};

// IN SECONDS
export const ONE_MINUTE = 60;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_MONTH = 30 * ONE_DAY;
